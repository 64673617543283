
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.logo-style {

  &__option {
    display: flex;
    align-items: center;
  }

  &__label {
    width: 70px;
    flex-shrink: 0;
    margin-right: 16px;
    color: $color-black-op-70;
  }

  &__range {
    width: 100%;
    margin-right: 16px;
    position: relative;
  }

  &__range-progress {
    position: absolute;
    top: 12px;
    left: 0;
    background-color: $color-primary-1-day;
    height: 2px;
    width: 20px;
  }

  &__range-input {
    width: 100%;
    -webkit-appearance: none;
    height: 2px;
    background-color: $color-white-status;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: ew-resize;
      background-color: $color-primary-1-day;
      transition: box-shadow $base-animation;

      &:hover {
        box-shadow: 0 0 15px 0 rgba($color-primary-1-day, .5);
      }
    }
  }

  &__input {
    width: 70px;
    flex-shrink: 0;
  }

  &__image {
    object-fit: contain;
    object-position: left center;
  }
}
